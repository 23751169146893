import React, { useState } from 'react';

import ShapeYellowSvg from '../../assets/images/bg_sticky_job_apply.png';
import { IconDeploySelectorBig } from '../../Atoms/Icons/Icons';
import { Button } from '../../Molecules/Button/Button';

import './StickyJobApply.scss';

let classNames = require('classnames');

const StickyJobApply = ({ text, buttonLabel, buttonLink, target = '_self' }) => {
  const [expand, setExpand] = useState(true);
  return (
    <div className={classNames('sticky_job_apply', { 'expand': expand })}>
      <img src={ShapeYellowSvg} className="yellow_svg_bg" />
      <div className="sticky_elems">
        <span className="clickzone" onClick={(e) => setExpand(!expand)} ariaexpended={!expand ? 'false' : 'true'}></span>
        <span className="icon" onClick={(e) => setExpand(!expand)}>
          <IconDeploySelectorBig
            color="color_white"
          />
        </span>
        <div dangerouslySetInnerHTML={{ __html: text }}></div>
        <Button
          label={buttonLabel}
          link={buttonLink}
          target={target}
          customClass="bg_color_white"
        />
      </div>
    </div>

  );
};

export default StickyJobApply;
