import React, { useState, useEffect } from 'react';

import { graphql } from 'gatsby';

import AudioComponent from '../../../components/AudioComponent/AudioComponent';
import Breadcrumb from '../../../components/Breadcrumb/Breadcrumb';
import DiscoverOfferBox from '../../../Molecules/DiscoverOfferBox/DiscoverOfferBox';
import { removeHtmlTag } from '../../../Utils/Utils';
import IntroPage from '../../../components/ContentType/IntroPage/IntroPage';
import KeyNumbers from '../../../components/KeyNumbers/KeyNumbers';
import Layout from '../../../Layout';
import PageBanner from '../../../components/PageBanner/PageBanner';
import ScrollPageNav from '../../../components/ScrollPageNav/ScrollPageNav';
import Seo from '../../../components/Seo/Seo';
import ShapeBackground from '../../../Molecules/ShapeBackground/ShapeBackground';
import SliderComponent from '../../../components/SliderComponent/SliderComponent';
import StickyJobApply from '../../../Molecules/StickyJobApply/StickyJobApply';
import TextVisuelTwoCol from '../../../components/ContentType/TextVisuelTwoCol/TextVisuelTwoCol';
import TitlePage from '../../../Molecules/TitlePage/TitlePage';
import TitleSection from '../../../Molecules/TitleSection/TitleSection';
import { useIntl } from '../../../../plugins/publicis-gatsby-plugin-i18n';
import VisuelTextOverlappedBlock from '../../../components/ContentType/VisuelTextOverlappedBlock/VisuelTextOverlappedBlock';
import useMedias from '../../../hooks/useMedias';

import './talents.scss';
let classNames = require('classnames');

const TalentsPage = ({ data, pageContext }) => {
  const intl = useIntl();
  const { processUrl, getImage } = useMedias();

  const { locale } = pageContext;
  const talentsData = data?.talentData?.edges[0]?.node;
  const imagesArray = data?.allImages?.edges;
  const [listsWhyTOjoin, setListsWhyTOjoin] = useState([]);
  const [allText, setAllText] = useState('');
  const metaTags =  talentsData.metatag;
  let metaTitle = '';
  let metaDesc = '';
  
  metaTags.forEach((meta) => {
    if(meta.attributes.name === 'title'){
        metaTitle = meta.attributes.content;
    }
    if(meta.attributes.name === 'description'){
        metaDesc = meta.attributes.content;
    }
  });

  let globalText = '';
  let haveAtLeastOneBasicBlock = 0;
  let innerNavArr = [];

  const slider_why_join_settings = {
    className: 'hash_dots_slider',
    centerMode: true,
    infinite: false,
    centerPadding: '20px',
    slidesToShow: 1,
    speed: 500,
    initialSlide: 0,
    arrows: true,
    dots: true,
    customPaging: function (i) {
      return (
        <a>
          {`#${i + 1}.`}
          <svg width="36.961" height="6" viewBox="0 0 36.961 6">
            <path
              d="M31.654 4.412A10.487 10.487 0 0126.478 6h-16a10.481 10.481 0 01-5.175-1.588A39.3 39.3 0 01-.001 0h36.963a39.323 39.323 0 01-5.308 4.412z"
              fill="#ffed00"
            ></path>
          </svg>
        </a>
      );
    },
  };

  useEffect(() => {
    let listJoin = [];
    talentsData.relationships.field_blocs.forEach((block) => {
      if (block.__typename === 'block_content__basic') {
        globalText += `${block?.field_title?.processed != undefined &&
          block?.field_title?.processed != null
          ? block?.field_title?.processed
          : ''
          }.${block?.body?.processed}.`;
        listJoin.push({
          title: block?.field_title?.processed,
          text: block?.body?.processed,
          imagePadding: block?.field_no_padding,
          imageAlignement: block?.field_alignment,
          field_backgroud_color: block?.field_backgroud_color,
          field_infinite_border: block?.field_infinite_border,
          field_border_top: block?.field_border_top,
          field_border_bottom: block?.field_border_bottom,
          field_position_bordure_haut: block?.field_position_bordure_haut,
          visuel: getImage(
            imagesArray,
            block?.relationships?.field_image?.drupal_internal__mid
          ),
        });
      } else if (block.__typename === 'block_content__block_texte') {
        globalText += `${block.field_title?.processed != undefined &&
          block.field_title?.processed != null
          ? block.field_title?.processed
          : ''
          }.${block.field_subtitle?.processed != undefined &&
            block.field_subtitle?.processed != null
            ? block.field_subtitle?.processed
            : ''
          }.${block.body?.processed}`;
        block?.relationships?.field_section.map((section, j) => {
          globalText += `${section?.relationships?.field_bloc_texte[0]?.field_title
            ?.processed != undefined &&
            section?.relationships?.field_bloc_texte[0]?.field_title
              ?.processed != null
            ? section?.relationships?.field_bloc_texte[0]?.field_title
              ?.processed
            : ''
            }.${section?.relationships?.field_bloc_texte[0]?.field_bloc_text_1
              ?.processed
            }.`;
        });
      }
    });
    setListsWhyTOjoin(listJoin);
    setAllText(
      removeHtmlTag(
        `${talentsData.title}.${talentsData.field_sous_titre !== null &&
          talentsData.field_sous_titre !== undefined
          ? talentsData.field_sous_titre
          : ''
        }.${talentsData?.body?.processed != undefined &&
          talentsData?.body?.processed != null
          ? talentsData?.body?.processed
          : ''
        }.${globalText}`
      )
    );
  }, []);

  const getSectionName = (title) => {
    let secName = '';

    innerNavArr.forEach((item) => {
      if (item.title === title) secName = item.section;
      return false;
    });
    return secName;
  };

  const setInnerNav = (classname_, title) => {
    let t = title?.replace(/<[^>]*>?/gm, '');
    if (
      innerNavArr.filter((a) => a?.section?.includes(classname_) === true)
        .length <= 0
    ) {
      innerNavArr.push({
        title: t,
        section: classname_,
      });
    }
  };

  return (
    <Layout>
      <Seo
        title={talentsData?.field_metatag?.title ? talentsData?.field_metatag?.title : metaTitle}
        description={talentsData?.field_metatag?.description ? talentsData?.field_metatag?.description : metaDesc}
      />
      <div
        className={classNames(
          'page_template',
          'zero_bottom_padding',
          'page_talents'
        )}
      >
        <ScrollPageNav data={innerNavArr} />
        <PageBanner
          visuel={getImage(
            imagesArray,
            talentsData?.relationships?.field_image?.drupal_internal__mid
          )}
          scroll={true}
        >
          <div className="wrapper_page">
            <Breadcrumb
              page_banner={true}
              data={{
                currentPage: { title: talentsData?.title, url: '' },
                locale: talentsData.langcode,
              }}
            />

            <div
              className={classNames({
                title_audio:
                  allText &&
                  talentsData.field_version_audio &&
                  allText.length > 30,
              })}
            >
              <TitlePage
                color="color_white"
                title={talentsData?.title}
                description={talentsData?.body?.processed}
              />
              {allText &&
                talentsData.field_version_audio &&
                allText.length > 30 && (
                  <AudioComponent
                    text={intl.formatMessage({
                      id: 'detailsActivities.button.listen.label',
                    })}
                    textToRead={allText}
                    color="color_white"
                  />
                )}
            </div>
          </div>
        </PageBanner>

        {talentsData.relationships.field_blocs.map((block, i) => {
          switch (block.__typename) {
            case 'block_content__block_contact':
              return (
                <div
                  key={i}
                  className={
                    block?.field_type_bloc_contact === 'sticky'
                      ? 'sticky_container_jobs'
                      : null
                  }
                >
                  {block?.field_type_bloc_contact === 'sticky' && (
                    <StickyJobApply
                      text={block?.body?.processed}
                      buttonLabel={intl.formatMessage({
                        id: 'talent.button.apply.label',
                      })}
                      buttonLink={block?.field_link?.url}
                      target={block?.field_nouvelle_fenetre && '_blank'}
                    />
                  )}
                </div>
              );

            case 'block_content__block_edito':
              setInnerNav(
                'intro_page_component',
                block?.field_title?.processed
              );
              globalText += `${block.field_title?.processed}.${block?.field_subtitle?.processed != undefined &&
                block?.field_subtitle?.processed != null
                ? block?.field_subtitle?.processed
                : ''
                }.${block?.field_citation != undefined &&
                  block?.field_citation != null
                  ? block?.field_citation
                  : ''
                }.`;
              return (
                <IntroPage
                  key={i}
                  title={block?.field_title?.processed}
                  subtitle={block?.field_subtitle?.processed}
                  visuel={getImage(
                    imagesArray,
                    block.relationships?.field_image?.drupal_internal__mid
                  )}
                  text={null}
                  size="wrapper" //border
                  quote={{
                    lang: locale,
                    text: block?.field_citation /*,
                    name: 'Prénom Nom'*/,
                  }}
                />
              );

            case 'block_content__basic':
              haveAtLeastOneBasicBlock++;
              haveAtLeastOneBasicBlock === 1 &&
                setInnerNav(
                  'section_index_' + i,
                  listsWhyTOjoin.filter((a) => a.title != null)[0]?.title
                );
              let title = listsWhyTOjoin.filter((a) => a.title != null)[0]
                ?.title;
              return (
                <div key={i}>
                  {haveAtLeastOneBasicBlock === 1 && (
                    <section
                      className={classNames(
                        'section_content section_lists_why_to_join',
                        getSectionName(title)
                      )}
                    >
                      <ShapeBackground
                        top={
                          listsWhyTOjoin[0]?.field_border_top &&
                            listsWhyTOjoin[0]?.field_position_bordure_haut ===
                            'gauche'
                            ? 'left'
                            : listsWhyTOjoin[0]?.field_border_top &&
                            listsWhyTOjoin[0]?.field_position_bordure_haut !=
                            null &&
                            'right'
                        }
                        color={
                          listsWhyTOjoin[0]?.field_backgroud_color === 'ice'
                            ? 'catskill_white'
                            : listsWhyTOjoin[0]?.field_backgroud_color ===
                              'navy'
                              ? 'dark_bleu'
                              : 'white'
                        }
                        bottomInfinite={
                          listsWhyTOjoin[0]?.field_infinite_border
                        }
                        bottom={listsWhyTOjoin[0]?.field_border_bottom}
                      >
                        <div className="wrapper_page_xs">
                          <TitleSection
                            title={title}
                            type="arrow"
                            h2color={
                              listsWhyTOjoin[0]?.field_backgroud_color ===
                                'navy'
                                ? 'white'
                                : 'bleu'
                            }
                            h4color={
                              listsWhyTOjoin[0]?.field_backgroud_color ===
                                'navy'
                                ? 'white'
                                : 'bleu'
                            }
                          />
                        </div>
                        <div className="only_mobile">
                          <SliderComponent settings={slider_why_join_settings}>
                            {listsWhyTOjoin.map((whyToJoinItem, i) => (
                              <div key={i}>
                                <div className="visuel">
                                  <img
                                    src={processUrl(
                                      whyToJoinItem.visuel.uri.url
                                    )}
                                    alt={whyToJoinItem.title}
                                    style={{ height: '74vw', width: '100%' }}
                                  />
                                </div>
                                <div className="upper_text">
                                  <h5
                                    className="title_hash"
                                    dangerouslySetInnerHTML={{
                                      __html: whyToJoinItem.title,
                                    }}
                                  ></h5>
                                  <div
                                    dangerouslySetInnerHTML={{
                                      __html: whyToJoinItem.text,
                                    }}
                                  ></div>
                                </div>
                              </div>
                            ))}
                          </SliderComponent>
                        </div>
                        <div className="only_tablet_desktop wrapper_page_xs">
                          {listsWhyTOjoin.map((whyToJoinItem, index) => (
                            <TextVisuelTwoCol
                              key={index}
                              orderInverse={
                                whyToJoinItem?.imageAlignement === 'right'
                                  ? true
                                  : false
                              }
                              extremeLeft={
                                whyToJoinItem?.imagePadding &&
                                  whyToJoinItem?.imageAlignement === 'left'
                                  ? true
                                  : false
                              }
                              extremeRight={
                                whyToJoinItem?.imagePadding &&
                                  whyToJoinItem?.imageAlignement === 'right'
                                  ? true
                                  : false
                              }
                              visuel={whyToJoinItem?.visuel}
                              titleHash={false}
                              text={whyToJoinItem?.text}
                              colorTitle={
                                listsWhyTOjoin[0]?.field_backgroud_color ===
                                  'navy'
                                  ? 'color_white'
                                  : 'color_bleu'
                              }
                              colorDescription={
                                listsWhyTOjoin[0]?.field_backgroud_color ===
                                  'navy'
                                  ? 'color_white'
                                  : 'color_bleu'
                              }
                            />
                          ))}
                        </div>
                      </ShapeBackground>
                    </section>
                  )}
                </div>
              );

            case 'block_content__block_chiffres_clefs':
              // eslint-disable-next-line no-case-declarations
              const dataArr = [];
              block.relationships?.field_values?.forEach((item) => {
                dataArr.push({
                  number: item.field_chiffre,
                  title: item.field_legend?.processed,
                });
              });
              setInnerNav(
                'key_numbers_component',
                block.field_title?.processed
              );
              return (
                <KeyNumbers
                  key={i}
                  title={block.field_title?.processed}
                  subtitle={block.body?.processed?.replace(/<p[^>]*>/g, '')}
                  sectionName={
                    'key_numbers_component ' +
                    getSectionName(block.field_title?.processed)
                  }
                  textColor="white"
                  backgroundImg={getImage(
                    imagesArray,
                    block.relationships?.field_image?.drupal_internal__mid
                  )}
                  datas={dataArr}
                  locale={locale}
                />
              );

            case 'block_content__block_cta_avec_image_de_fond':
              setInnerNav('collapse_with_bottom_section', 'hide');
              return (
                <div key={i}>
                  <section
                    className={classNames(
                      'section_content',
                      'collapse_with_bottom_section'
                    )}
                  >
                    <div className="wrapper_page">
                      <DiscoverOfferBox
                        visuel={getImage(
                          imagesArray,
                          block?.relationships?.field_image
                            ?.drupal_internal__mid
                        )}
                        label={block?.field_link?.title}
                        link={block?.field_link?.uri}
                        target={
                          block?.field_nouvelle_fenetre ? '_blank' : '_self'
                        }
                      />
                    </div>
                  </section>
                </div>
              );

            case 'block_content__block_texte':
              setInnerNav(
                'section_human_resources',
                block.field_title?.processed
              );

              return (
                <section
                  className={classNames(
                    'section_content section_human_resources special_top_marge',
                    getSectionName(block.field_title?.processed)
                  )}
                  key={i}
                >
                  <ShapeBackground
                    top={block.field_border_top && 'right'}
                    color={
                      block.field_backgroud_color === 'ice'
                        ? 'catskill_white'
                        : block.field_backgroud_color === 'navy'
                          ? 'dark_bleu' //z-index-top
                          : 'white'
                    }
                    bottomInfinite={block.field_infinite_border}
                    bottom={block.field_border_bottom_display && 'left'}
                  >
                    <div className="wrapper_page_xs">
                      <TitleSection
                        title={block?.field_title?.processed}
                        description={block?.field_subtitle?.processed}
                        type="arrow"
                        h2color={
                          block.field_backgroud_color === 'ice'
                            ? 'bleu'
                            : 'white'
                        }
                        h4color={
                          block.field_backgroud_color === 'ice'
                            ? 'bleu'
                            : 'yellow'
                        }
                      />
                    </div>
                    <VisuelTextOverlappedBlock
                      extremeLeft={true}
                      visuel={getImage(
                        imagesArray,
                        block?.relationships?.field_image?.drupal_internal__mid
                      )}
                      alt=""
                      bgColor="color_sky_bleu"
                      text={block?.body?.processed}
                    />
                    <div className="wrapper_page_xs">
                      {block?.relationships?.field_section.map((section, j) => {
                        globalText += `${section?.relationships?.field_bloc_texte[0]
                          ?.field_title?.processed != undefined &&
                          section?.relationships?.field_bloc_texte[0]
                            ?.field_title?.processed != null
                          ? section?.relationships?.field_bloc_texte[0]
                            ?.field_title?.processed
                          : ''
                          }.${section?.relationships?.field_bloc_texte[0]
                            ?.field_bloc_text_1?.processed
                          }.`;
                        return (
                          <TextVisuelTwoCol
                            key={j}
                            extremeLeft={
                              section?.field_no_padding &&
                                section?.field_alignment === 'left'
                                ? true
                                : false
                            }
                            extremeRight={
                              section?.field_no_padding &&
                                section?.field_alignment === 'right'
                                ? true
                                : false
                            }
                            orderInverse={
                              section?.field_alignment === 'right'
                                ? true
                                : false
                            }
                            extactHalf={
                              section?.field_alignment === 'left' ? true : false
                            }
                            visuel={getImage(
                              imagesArray,
                              section?.relationships?.field_image
                                ?.drupal_internal__mid
                            )}
                            colorTitle={
                              block.field_backgroud_color === 'navy'
                                ? 'color_white'
                                : undefined
                            }
                            colorDescription={
                              block.field_backgroud_color === 'navy' &&
                              'color_white'
                            }
                            text={
                              section?.relationships?.field_bloc_texte[0]
                                ?.field_bloc_text_1?.processed
                            }
                            title={
                              section?.relationships?.field_bloc_texte[0]?.field_title?.processed
                            }
                          />
                        );
                      })}
                    </div>
                  </ShapeBackground>
                </section>
              );

            default:
              break;
          }
        })}
      </div>
    </Layout>
  );
};

export const query = graphql`
  query TalentTemplateQuery(
    $locale: String!
    $slug: String!
    $imagesID: [Int!]
  ) {
    talentData: allNodePage(
      filter: { langcode: { eq: $locale }, path: { alias: { eq: $slug } } }
    ) {
      edges {
        node {
          title
          langcode
          field_version_audio
          path {
            alias
          }
          field_metatag {
            description
            title
          }
          metatag {
            attributes {
              content
              name
            }
          }
          relationships {
            field_image {
              drupal_internal__mid
            }
            field_blocs {
              __typename
              ... on Node {
                ... on block_content__basic {
                  id
                  field_alignment
                  field_no_padding
                  field_backgroud_color
                  field_infinite_border
                  field_border_top
                  field_border_bottom
                  field_position_bordure_haut
                  field_title {
                    processed
                  }
                  body {
                    processed
                  }
                  relationships {
                    field_image {
                      drupal_internal__mid
                    }
                  }
                }
                ... on block_content__block_edito {
                  id
                  field_title {
                    processed
                  }
                  field_subtitle {
                    processed
                  }
                  relationships {
                    field_image {
                      drupal_internal__mid
                    }
                  }
                  field_citation
                }
                ... on block_content__block_cta_avec_image_de_fond {
                  field_link {
                    title
                    uri
                  }
                  field_nouvelle_fenetre
                  relationships {
                    field_image {
                      drupal_internal__mid
                    }
                  }
                }
                ... on block_content__block_texte {
                  id
                  field_title {
                    processed
                  }
                  body {
                    processed
                  }
                  field_subtitle {
                    processed
                  }
                  field_backgroud_color
                  field_infinite_border
                  field_border_top
                  field_border_bottom_display
                  relationships {
                    field_image {
                      drupal_internal__mid
                    }
                    field_section {
                      field_no_padding
                      field_alignment
                      relationships {
                        field_image {
                          drupal_internal__mid
                        }
                        field_bloc_texte {
                          field_theme_title
                          field_nouvelle_fenetre
                          field_bloc_text_1 {
                            processed
                          }
                          field_title {
                            processed
                          }
                        }
                      }
                    }
                  }
                }
                ... on block_content__block_contact {
                  field_type_bloc_contact
                  field_nouvelle_fenetre
                  body {
                    processed
                  }
                  field_link {
                    url
                  }
                }
                ... on block_content__block_chiffres_clefs {
                  body {
                    processed
                  }
                  field_title {
                    processed
                  }
                  relationships {
                    field_image {
                      drupal_internal__mid
                    }
                    field_values {
                      field_legend {
                        processed
                      }
                      field_chiffre
                    }
                  }
                }
              }
            }
          }
          body {
            processed
          }
        }
      }
    }
    allImages: allMediaImage(
      filter: {
        langcode: { eq: "fr" }
        drupal_internal__mid: { in: $imagesID }
      }
    ) {
      edges {
        node {
          drupal_internal__mid
          relationships {
            field_media_image {
              uri {
                url
              }
            }
          }
        }
      }
    }
  }
`;

export default TalentsPage;
